import request from '@/utils/request'


// 查询收益列表
export function listIncome(query) {
  return request({
    url: '/income/income/list',
    method: 'get',
    params: query
  })
}

// 查询收益分页
export function pageIncome(query) {
  return request({
    url: '/income/income/page',
    method: 'get',
    params: query
  })
}

// 查询收益详细
export function getIncome(data) {
  return request({
    url: '/income/income/detail',
    method: 'get',
    params: data
  })
}

// 新增收益
export function addIncome(data) {
  return request({
    url: '/income/income/add',
    method: 'post',
    data: data
  })
}

// 修改收益
export function updateIncome(data) {
  return request({
    url: '/income/income/edit',
    method: 'post',
    data: data
  })
}

// 删除收益
export function delIncome(data) {
  return request({
    url: '/income/income/delete',
    method: 'post',
    data: data
  })
}
